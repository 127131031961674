import React from 'react';
import { action, observable } from 'mobx';

class Page {
    constructor(data) {
        // this.rawData = data;

        this.path = data.path;
        this.type = data.type;
        this.title = data.title;
        this.content = data.content;
        this.meta = data.meta;
    }
}

class ContentPage extends Page {
    constructor(data) {
        super(data);

        this.intro = data.intro;
        this.image = data.image;
        this.layout = data.layout || 'stacked';
    }
}

class ContactPage extends Page {
    // constructor(data) {
    //     super(data);
    // }
}

class Dialog {
    constructor(data) {
        this.title = data.title;
        this.number = data.number;
        this.showTitle = data.showTitle;
        this.first = data.first;
        this.position = data.position;
        this.mobileTop = data.mobileTop;
        this.mobileLeft = data.mobileLeft;
        this.top = data.top;
        this.left = data.left;
        this.width = data.width;
        this.mobileWidth = data.mobileWidth;
        this.tabletWidth = data.tabletWidth;
        this.height = data.height;
        this.content = data.content;
        this.link = data.link;
        this.linkText = data.linkText;
    }
}

class Tile {
    constructor(data) {
        this.filename = data.filename;
        this.mobileFilename = data.mobileFilename;
        this.mobileImageWidth = data.mobileImageWidth;
        this.offset = data.offset;
        this.mobileOffset = data.mobileOffset;
        this.animations = data.animations;

        if (data.dialogs) {
            this.dialogs = this.parseDialogs(data.dialogs);
        }
    }

    parseDialogs(dialogs) {
        return dialogs.map(dialog => {
            return new Dialog(dialog);
        });
    }
}

class TileGroup {
    constructor(data) {
        this.name = data.name;
        this.first = data.first;
        this.heading = data.heading;
        this.subheading = data.subheading;
        this.leftToRight = data.leftToRight;
        this.offset = data.offset;
        this.mobileOffset = data.mobileOffset;

        this.animations = data.animations;
        if (data.tiles) {
            this.tiles = this.parseTiles(data.tiles);
        }
    }

    parseTiles(tiles) {
        return tiles.map(tile => {
            return new Tile(tile);
        });
    }
}

class HomePage extends Page {
    @observable showSplash = false;
    @observable currentDialog = null;
    @observable showContainer = false;

    @action setShowSplash = val => this.showSplash = val;
    @action setDialog = val => this.currentDialog = val;
    @action unsetDialog = () => this.currentDialog = null;
    @action setShowContainer = val => this.showContainer = val;

    constructor(data) {
        super(data);

        this.tilesGroups = this.parseTileGroups(data.tilesGroups);
    }

    get filenames() {
        const filenames = [];
        this.tilesGroups.forEach(group => {
            if (group.tiles) {
                group.tiles.forEach(tile => {
                    filenames.push(tile.filename);
                    filenames.push(tile.mobileFilename);
                });
            }
        });
        return filenames;
    }

    parseTileGroups(tileGroups) {
        return tileGroups.map(tileGroup => {
            return new TileGroup(tileGroup);
        });
    }
}

class MenuItem {
    submenu = null;

    constructor(data) {
        // this.rawData = data;

        this.name = data.name;
        this.link = data.link;

        if (data.submenu) {
            this.submenu = this.parseMenu(data.submenu);
        }
    }

    parseMenu(menu) {
        return menu.map(item => {
            return new MenuItem(item);
        });
    }
}

export default class Store {
    @observable pages = [];
    menu = [];

    constructor(data) {
        // this.rawData = data;
        this.pages = this.parsePages(data.pages);
        this.menu = this.parseMenu(data.menu);
        this.general = data.general;
    }

    parsePages(pages) {
        const pageMap = {
            'homepage': HomePage,
            'contentpage': ContentPage,
            'contactpage': ContactPage,
        };
        return pages.map(page => {
            const { type } = page;
            const PageClass = pageMap[type];
            return new PageClass(page);
        });
    }

    parseMenu(menu) {
        return menu.map(item => {
            return new MenuItem(item);
        });
    }
}
