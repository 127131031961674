import { oneLine } from 'common-tags/es';

const data = {
    type: 'contentpage',
    path: '/measures/essentials',
    meta: {
        title: 'Access to essentials | Child Poverty',
    },
    title: 'Access to essentials',
    intro: [
        oneLine`<p>The monitor shows <b>11.0%</b> of all children living in households in material hardship - that's
                125,700 children in households regularly going without basic essentials.</p>`,
    ],
    layout: 'image-right',
    image: {
        filename: 'Essentials_2022.png',
        alt: 'Graph of children who live in households experiencing material hardship by group. (%)',
    },
    content: [
        oneLine`The Government has committed to reduce this to <b>6%</b> by 2028</p>`,
        oneLine`<p>Digging deeper, we see stark inequities for some groups of children. <b>24.0%</b> Pacific, <b>20.5%</b> 
                disabled and <b>20.2%</b> Māori children experience material hardship, compared with only <b>7.8%</b> 
                European and <b>9.7%</b> non-disabled children.</p>`,
        oneLine`<p>Material hardship means being unable to afford six essentials, such as having enough to eat, warm clothes and sturdy shoes.</p>`,
        oneLine`<p>Households going without nine or more essentials are defined as being in severe material hardship. 
                The monitor found <b>4.9%</b> of all children living in severe material hardship. This was double for 
                tamariki Māori at <b>9.5%</b>, and Pacific children (<b>10.6%</b>), 
                while only <b>3.4%</b> of European children experience severe material hardship. For disabled children 
                this is <b>10.3%</b>. A much more targeted effort will be needed to get fair and just outcomes for 
                all children.</p>`,
        oneLine`<p>These rates don’t necessarily take into account what children say they need for a ‘good life’.
                We know that many children growing up in hardship experience love and support from their whānau,
                families and aiga around them. However, missing out on essentials can make children sick, affect 
                their education, and have long term impacts on their lives.</p>`,
    ],
};

export default data;
