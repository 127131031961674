import React from 'react';

import Animation from './animation';
import Dialog from './dialog';
import Card from './card';
import Img from './img';

const Tile = ({ tile, currentDialog, setDialog, unsetDialog }) => {
    return (
        <div className='tiles__tile md+:w-1/2 md+:pointer-events-none'>
            { tile.offset && (<div className='hidden md+:block' style={{ marginTop: tile.offset }} />) }
            { tile.mobileOffset && (<div className='md+:hidden' style={{ marginTop: tile.mobileOffset }} />) }
            <div className='relative'>
                <Img className='hidden md+:block' filename={ tile.filename } />
                <Img className='md+:hidden' filename={ tile.mobileFilename } style={{ 'width': tile.mobileImageWidth }} />

                { tile.animations && (
                    tile.animations.map(animation => <Animation key={ animation.filename } animation={ animation } />)
                ) }
            </div>
            { tile.dialogs && (
                tile.dialogs.map((dialog, key) => (
                    <Card
                        key={ key }
                        dialog={ dialog }
                        currentDialog={ currentDialog }
                        setDialog={ setDialog }
                        unsetDialog={ unsetDialog }
                    />
                ))
            ) }
        </div>
    );
};

export default Tile;
