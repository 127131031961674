import React from 'react';
import { observer } from 'mobx-react';

import Menu from '../menu';
import Page from './page';
import Footer from '../footer';

const NotFoundPage = (observer(({ page }) => {
    return (
        <>
            <Menu />
            <Page page={ page }>
                <div className="bg-white">
                    <div className="container pb-32">
                        <div className="md:o1 md:c10">
                            <h2 className="mb-4">Page not found</h2>
                            <h4>Sorry, the page you were looking for couldn't be found.</h4>
                        </div>
                    </div>
                </div>
            </Page>
            <Footer />
        </>
    );
}));

export default NotFoundPage;
