import { html, oneLine } from 'common-tags/es';

const data = {
    type: 'contentpage',
    path: '/about',
    meta: {
        title: 'About the Project | Child Poverty',
    },
    title: 'About the project',
    intro: oneLine`The Child Poverty Monitor reports on child poverty in Aotearoa <br class="hidden md+:inline" />
        New Zealand and its impacts.`,
    content: [
        oneLine`<p>2022 is the 10th year we have released the Child Poverty Monitor.</p>`,
        oneLine`<p>The Child Poverty Monitor was started by former Children’s Commissioner Dr Russell Wills who wanted to raise awareness of the impacts of poverty on children. Subsequent Children’s Commissioners have advocated for measuring and reducing poverty among children in Aotearoa New Zealand.</p>`,
        oneLine`<p>In addition to the government’s official annual child poverty statistics, this year several other reports have also been released on the subject eg: </p>`,
        html` <div class="list">
                <ul>
                    <li> MSD report on <a rel="noreferrer noopener" target="_blank" href='https://www.msd.govt.nz/about-msd-and-our-work/publications-resources/research/child-poverty-in-nz/index.html' class="font-semibold underline-link color-orange">Child Poverty in New Zealand.</a></li>
                    <li> Productivity Commission interim report <a rel="noreferrer noopener" target="_blank" href="https://www.productivity.govt.nz/inquiries/a-fair-chance-for-all/" class="font-semibold underline-link color-orange">A Fair Chance For All.</a></li>
                    <li> Kore Hiakai report <a rel="noreferrer noopener" target="_blank" href="https://www.zerohunger.org.nz/kamakona2022" class="font-semibold underline-link color-orange">Ka Mākona.</a></li>
                </ul>
              </div>        
        `,
        oneLine`<p>These reports agree that progress has been made to reduce income poverty and material disadvantage through policy decisions and investment. The Government passed the Child Poverty Reduction Act (2018) that requires the government to both measure and set targets to reduce child poverty. This is important progress, however, to achieve equity across society there is still work to do.</p>`,
        oneLine`<p>Strong communities have their own responses to mitigating poverty. People helping people is a theme this year in the Child Poverty Monitor technical report. We tell the stories of some community organisations and young people who are making lives better for those with low incomes and living in hardship. In doing this work these young people are developing their understanding of social justice, building their sense of self-worth and their ability to overcome challenges.</p>`,
        oneLine`<p>Go to <a rel="noreferrer noopener" target="_blank" href="https://www.occ.org.nz/wellbeing/" class="font-semibold underline-link color-orange">www.occ.org.nz/wellbeing</a> to read these stories, told through the voices of rangatahi.</p>`,
    ],
};

export default data;
