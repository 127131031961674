import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring/web.cjs';
import { observer } from 'mobx-react';

import Page from './page';
import TileGroup from '../tile-group';
import Menu from '../menu';
import Footer from '../footer';
import Img from '../img';
import preloadImages from '../../utils/preloadImages';
import scrollToTop from '../../utils/scrollToTop';
import { trackEvent, trackPage } from '../../utils/analytics';

const SplashScreen = ({ content, filenames, onEnd }) => {
    const [position, setPosition] = useState(0);
    const [items, setItem] = useState([
        { key: 0, content: content[position] },
    ]);
    const images = [];
    useEffect(() => {
        preloadImages(images, filenames);
    }, []);

    const next = (item) => {
        const { key } = item;
        const nextKey = position + 1;
        if (nextKey < content.length) {
            setPosition(nextKey);
            setItem({ key: nextKey, content: content[nextKey] });
        } else if (key + 1 === content.length) {
            onEnd();
        }
    };

    const transitions = useTransition(items, item => item.key, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { display: 'none' },
        onRest: next,
        config: {
            mass: 5,
            tension: 50,
            friction: 60,
        },
    });

    const handleSkipIntroClick = function () {
        onEnd();
        trackEvent('click', 'skip', 'introduction skipped');
    };

    return (
        <div className="bg-dark-green min-h-screen">
            <div className="container min-h-screen">
                <div className="flex flex-jc-around flex-column flex-ai-center min-h-screen relative h-px ">
                    <div className="text-center color-dark-blue relative w-full">
                        <Img className="w-56 md:w-64 mb-12" filename="PovertyMonitor_2022_REV.png" alt="Child Poverty Monitor" />
                        { transitions.map(({ item, key, props }) => (
                            <animated.p
                                key={ key }
                                className="font-medium text-lg md:text-4xl md:font-light"
                                style={ props }
                                dangerouslySetInnerHTML={{ __html: item.content }}
                            />
                        ))}
                        <div className="py-8">
                            <a className="underline-link color-dark-blue" onClick={ handleSkipIntroClick }>
                                Skip intro
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const HomePage = observer(({ page }) => {
    const { content, tilesGroups, filenames, showSplash, setShowSplash, currentDialog, setDialog, unsetDialog, setShowContainer, showContainer } = page;

    useEffect(() => {
        scrollToTop();
        trackPage(page);
        return () => {
            // When the homepage unmounts set it back to visibility:hidden.
            setShowContainer(false);
        };
    }, []);

    if (showSplash) {
        return (
            <Page page={ page }>
                <SplashScreen content={ content } filenames={ filenames } onEnd={ () => setShowSplash(false) } />
            </Page>
        );
    }
    if (!showContainer) {
        // Container is visibility:hidden until it has a chance to position everything.
        // This stops everything jumping around all over the shop on first load.
        setTimeout(() => {
            setShowContainer(true);
        }, 20);
    }

    return (
        <>
            <div className="bg-dark-green">
                <Menu background="bg-dark-green" />
                <Page page={ page }>
                    <div className="" style={{ overflowX: 'hidden' }}>
                        <div className={ `container ${ showContainer ? '' : 'visibility-hidden' }` }>
                            { tilesGroups.map(tileGroup => (
                                <TileGroup
                                    key={ tileGroup.name }
                                    tileGroup={ tileGroup }
                                    currentDialog={ currentDialog }
                                    setDialog={ setDialog }
                                    unsetDialog={ unsetDialog }
                                />
                            )) }
                        </div>
                    </div>
                </Page>
            </div>
            <Footer background="bg-white" />
        </>
    );
});

export default HomePage;
