import React, { Fragment } from 'react';

import { isString } from 'lodash/lang';
import { Link } from 'react-router-dom';
import { oneLine } from 'common-tags';
import PositionedDiv from './positioned-div';
import Img from './img';


const Card = ({ dialog }) => {
    const { title, icon, content, link, top, left, mobileTop, mobileLeft, width, mobileWidth, tabletWidth, height, linkText } = dialog;
    const boxClassName = oneLine`
        tiles__dialog-box md+:absolute pointer-events-auto flex flex-ai-stretch
        `;
    const innerClassName = oneLine`
        tiles__dialog-box-inner top w-full
        px-2 py-1 rounded-5 shadow-2 text-center
        `;
    return (
        <PositionedDiv
            className={ boxClassName }
            top={ top }
            left={ left }
            mobileTop={ mobileTop }
            mobileLeft={ mobileLeft }
            width={ width }
            mobileWidth={ mobileWidth }
            tabletWidth={ tabletWidth }
            height={ height }
        >
            <div className={ innerClassName }>
                <div className='p5 relative z-10'>
                    <h2 className='color-peach uppercase text-xs tracking-4 mb-4'>{ title }</h2>
                    { icon && (
                        <Img
                            filename={ icon.filename }
                            style={{ width: icon.width }}
                            alt={ icon.alt }
                        />
                    ) }
                    { isString(content) ? (
                        <p className={ `max-w-sm mx-auto ${ link ? '' : 'mb-4' }` } dangerouslySetInnerHTML={{ __html: content }} />
                    ) : (
                        content.map((value, key) => {
                            return (
                                <Fragment key={ key }>
                                    <p className='max-w-sm mx-auto my-4' dangerouslySetInnerHTML={{ __html: value }} />
                                    { key < content.length - 1 && (<hr className='border-peach border-top border-solid max-w-56 m0 mx-auto' />) }
                                </Fragment>
                            );
                        })
                    ) }
                    { link && (
                        <p className="my-4">
                            <Link to={ link } className="underline-link font-bold color-link-blue uppercase text-xs tracking-4">
                                { linkText || 'Read more'}
                            </Link>
                        </p>
                    ) }
                </div>
            </div>
        </PositionedDiv>
    );
};

export default Card;
