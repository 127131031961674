import React from 'react';
import { Helmet } from 'react-helmet';

const Page = ({ children, page }) => {
    const { meta: { title } } = page;
    return (
        <>
            <Helmet>
                {/* <meta charSet='utf-8' /> */}
                {/* ^^^ marked for removal, this is already on the base.html template */}
                <title>{ title }</title>
            </Helmet>
            { children }
        </>
    );
};

export default Page;
