import React from 'react';

const Svg = ({ name, width, height, className = '' }) => {
    const style = {
        ...(width) && { width },
        ...(height) && { height },
    };

    return (
        <svg
            className={ `svg--${ name } ${ className }` }
            style={ style }
        >
            <use xlinkHref={ `/static/images/icon-sprite.svg#${ name }` } />
        </svg>
    );
};

export default Svg;
