import { oneLine } from 'common-tags/es';

const data = {
    type: 'contentpage',
    path: '/measures/reducing-child-poverty',
    meta: {
        title: 'Reducing child poverty | Child Poverty',
    },
    title: 'Reducing child poverty',
    intro: oneLine`Under the Child Poverty Reduction Act (2018) the government set a goal to halve child poverty and reduce it to <b>10%</b> of children (on one measure) over ten years – ie by 2028.`,
    content: [
        oneLine`<p>At a national level we’re on track to meet this target. But for Māori, Pacific and disabled children, a much greater effort will be needed if they are to reach that low rate of poverty too.</p>`,
        oneLine`<p>This year’s Child Poverty Monitor looks at how material hardship and poverty are experienced more by some children than others. Those who are Māori, Pacific and/or disabled, are much more likely to be disadvantaged.</p>`,
        oneLine`<p>Structural barriers like racism, colonialism, and ableism get in the way of these children having the same opportunity as others to live their best lives. Unless something changes, the Government’s poverty targets won’t be met for these groups.</p>`,
        oneLine`<p>Reducing child poverty to meet the targets will need radical systemic change.</p>`,
    ],
};

export default data;
