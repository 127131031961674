import { oneLine } from 'common-tags/es';

const data = {
    type: 'contentpage',
    path: '/measures/policy',
    meta: {
        title: 'Policy | Child Poverty',
    },
    title: 'There are solutions',
    intro: oneLine`We all want mokopuna to grow up in a family that has what they need to thrive.`,
    content: [
        oneLine`<p>Policy decisions have led Aotearoa New Zealand to enshrine in law, targets for reductions in child poverty (the Child Poverty Reduction Act, 2018).</p>`,
        oneLine`<p>The Child and Youth Wellbeing Strategy aims to improve wellbeing for all mokopuna and will result in positive impacts for the whole country, if it is fully implemented.</p>`,
        oneLine`<p>Improving incomes such as through taxes and income transfers can create more equity across society. Targeted efforts in this area are important policy mechanisms to reduce
child poverty at the source.</p>`,
        oneLine`<p>Other policy areas such as the Healthy Homes Guarantee Act 2017, improve living conditions for whānau with mokopuna.</p>`,
        oneLine`<p>Free health and dental care, period products in schools, and food in schools for children are further examples of policies that mitigate the impacts of poverty for children and their families, in non-stigmatising ways.</p>`,
    ],
};

export default data;
