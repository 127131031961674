import { oneLine } from 'common-tags/es';

const data = {
    type: 'contentpage',
    path: '/measures/health',
    meta: {
        title: 'Good health | Child Poverty',
    },
    // layout: 'image-right',
    title: 'Good health',
    intro: oneLine`<p>Free GP visits for children under 14 has improved access by children to
                      health care. However, there remain disparities in access to essential
                      services such as immunisations.</p>`,
    image: {
        filename: 'Health_2022.png',
        alt: 'Graph of Full immunisation rates of babies at 8 months old by ethnicity',
    },
    content: [
        oneLine`<p>The Covid-19 pandemic and response coincided with a drop in immunisation rates for
                   serious childhood diseases.</p>`,
        oneLine`<p><b>85.7%</b> of all babies at 8 months are fully immunised, for pēpi Māori, that has dropped to only
                   <b>72.1%</b>. These rates are all below the target of <b>95%</b> fully immunised.</p>`,
        oneLine`<p>The successful mobilisation of communities to encourage vaccination against Covid-19
                   could be used again to improve immunisations for other diseases and improve health
                   outcomes for mokopuna.</p>`,
    ],
};

export default data;
