import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import data from './data';
import App from './components';
import Store from './store';

import onPageReady from './utils/onPageReady';

function initApp() {
    const store = new Store(data);
    ReactDOM.render(
        <App store={ store } data={ data } />,
        document.getElementById('mount')
    );
}

function initialise() {
    initApp();

    if (__SENTRY_DSN) {
        import(/* webpackChunkName: "sentry" */ './modules/sentry.js')
            .then(({ default: sentry }) => {
                sentry.init(__SENTRY_DSN);
            });
    }
}

onPageReady(initialise);
