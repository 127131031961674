import { oneLine } from 'common-tags/es';

const data = {
    type: 'contentpage',
    path: '/measures/food',
    meta: {
        title: 'Healthy food | Child Poverty',
    },
    title: 'Healthy food',
    intro: oneLine`<p>In 2020/21 one in six children were living in households where food ran out sometimes
                   (<b>12.0%</b>) or often (<b>2.9%</b>) due to lack of money.</p>`,
    image: {
        filename: 'Food_2022.png',
        alt: 'Graph of Children without consistent access to enough food or healthy food by ethnicity',
    },
    content: [
        oneLine`<p>About <b>30%</b> of children in the most deprived areas live in households that run out of food
                sometimes or often compared to <b>4.6%</b> in the least deprived areas. Māori (<b>26.4%</b>) and Pacific
                (<b>37.3%</b>) are more likely to run out of food, compared with European (<b>10.9%</b>).</p>`,
        oneLine`<p>School lunch programmes and strong responses by communities during the Covid-19
                pandemic meant more people could access food. Knowing tamariki can come to school for a
                healthy lunch each day really takes the pressure off whānau.</p>`,
        oneLine`<p>However, it is better for families to have the resources they need to shop for themselves,
                and to buy the food they know their children want and will eat.</p>`,

    ],
};

export default data;
