export default function lottieIeFixer(el, addResizeListener=false) {

  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  if (!isIE11) {
    return;
  }

  if (addResizeListener) {
    window.addEventListener('resize', function () {
      lottieIeFixer(el);
    });
  }

  const width = el.clientWidth;
  const height = el.clientHeight;
  const svg = el.querySelector('svg');
  const svgWidth = parseInt(svg.getAttribute('width'), 10);
  const svgHeight = parseInt(svg.getAttribute('height'), 10);

  const ratio = width / svgWidth;
  svg.style.width = width + 'px';
  svg.style.height = (svgHeight * ratio) + 'px';

}
