import styled from 'styled-components';

const isDefined = val => {
    return val !== 'undefined';
};

const PositionedDiv = styled.div`
    ${ props => {
        const { top, left, mobileTop, mobileLeft, width, mobileWidth, tabletWidth, height } = props;
        let style = '';
        
        // @media (min-width: 425px) {
        //     outline: 2px solid red;
        //     width: ${ tabletWidth || 'inherit' };
        // }
        
        if (width && mobileWidth) {
            style += `
                width: ${ mobileWidth || '100%' };
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
                    
                @media (min-width: 1024px) {
                    width: ${ width };
                }
            `;
        } else if (width) {
            style += `
                width: ${ width }; 
            `;
        }

        if (height) {
            style += `
                @media (min-width: 1024px) {
                    max-height: ${ height };
                }
            `;
        }

        if (mobileTop && mobileLeft) {
            style += `
                top: ${ mobileTop };
                left: ${ mobileLeft };

                @media (min-width: 1024px) {
                    top: ${ top };
                    left: ${ left };            
                }
            `;
        } else {
            style += `
                top: ${ top };
                left: ${ left };
            `;
        }

        return style;
    }
}`;

export default PositionedDiv;
