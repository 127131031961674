import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { useSpring, animated } from 'react-spring/web.cjs';
import PositionedDiv from './positioned-div';
import lottieIeFixer from '../utils/lottie.ie.fixer';


const CssAnimation = ({ filename, width, top, left, mobileWidth, mobileTop, mobileLeft, animation, extraClasses = '' }) => {
    const path = `/static/images/${ filename }`;
    return (
        <PositionedDiv
            className={ `tiles__animation ${ extraClasses }` }
            width={ width }
            top={ top }
            left={ left }
            mobileWidth={ mobileWidth }
            mobileTop={ mobileTop }
            mobileLeft={ mobileLeft }
        >
            <img src={ path } style={{ animation }} />
        </PositionedDiv>
    );
};

const LottieAnimation = ({ filename, loop, autoplay = true, width, top, left, mobileWidth, tabletWidth, mobileTop, mobileLeft, overrideAnimationContainer, noAnimateIE=false }) => {
    const animationEl = useRef(null);
    const path = `/static/images/${ filename }`;

    useEffect(() => {
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        if (isIE11 && noAnimateIE) {
            autoplay = false;
            loop = false;
        }
        let animationContainer = animationEl.current;
        const animation = lottie.loadAnimation({
          path,
          loop,
          autoplay,
          container: animationContainer,
          renderer: 'svg',
        });
        animation.addEventListener('DOMLoaded', () => {
            if (overrideAnimationContainer) {
              animationContainer = overrideAnimationContainer.current;
            }
            lottieIeFixer(animationContainer, true);
        });
    }, []);

    return (
        <PositionedDiv
            ref={ animationEl }
            className="tiles__animation"
            width={ width }
            top={ top }
            left={ left }
            mobileWidth={ mobileWidth }
            tabletWidth={ tabletWidth }
            mobileTop={ mobileTop }
            mobileLeft={ mobileLeft }
        />
    );
};

const SpringAnimation = ({ filename, from, to, duration, loop, width, top, left }) => {
    const path = `/static/images/${ filename }`;

    const loopTo = async next => {
        while (1) {
            await next(to);
            await next(from);
        }
    };

    const props = useSpring({
        from,
        to: loop ? loopTo : to,
        config: { duration },
    });

    return (
        <animated.div
            className='tiles__animation'
            style={{
                width,
                top,
                left,
                ...props,
            }}
        >
            <img src={ path } />
        </animated.div>
    );
};

/*
    Uses React.memo with a comparison function which always returns true. This prevents animations from ever re-rendering.
 */
const Animation = React.memo(({ animation }) => {
    const { type } = animation;

    if (type === 'lottie') {
        return <LottieAnimation { ...animation } />;
    }
    if (type === 'spring') {
        return <SpringAnimation { ...animation } />;
    }
    if (type === 'css') {
        return <CssAnimation { ...animation } />;
    }
    return <></>;
});

export {
    CssAnimation,
    LottieAnimation,
    SpringAnimation,
};
export default Animation;
