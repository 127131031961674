export function trackPage(page) {
  window.gtag('config', window.GOOGLE_ANALYTICS_ID, {
    'page_title': page.title,
    'page_path': page.path,
    'page_location': window.location.href
  });
}

export function trackEvent(action, category, label, value=null) {
  window.gtag('event', action, {
    'event_category': category,
    'event_label': label,
    'event_value': value
  })
}
