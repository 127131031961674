import React, { useEffect, useRef } from 'react';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

import Tile from './tile';
import Animation from './animation';
import {isString} from 'lodash/lang';

const SubHeading = ({ subheading }) => {
    return (
        isString(subheading) ? (
            <h5
                className="color-dark-blue text-center py-4 md:c10 md:o1 pb-12 md:pb-32"
                dangerouslySetInnerHTML={{ __html: subheading }}
            />
        ) : (
            <>
                { subheading.map((s, key) => (
                    key === subheading.length - 1 ? (
                        <h5
                            key={ key }
                            className="color-dark-blue text-center py-4 md:c10 md:o1 pb-12 md+:pb-24"
                            dangerouslySetInnerHTML={{ __html: s }}
                        />
                    ) : (
                        <h5
                            key={ key }
                            className="color-dark-blue text-center py-4 md:c10 md:o1 pb-2"
                            dangerouslySetInnerHTML={{ __html: s }}
                        />
                    )
                )) }
            </>
        )
    );
};

const TileGroup = ({ tileGroup, currentDialog, setDialog, unsetDialog }) => {
    const tilesEl = useRef(null);

    const { heading, first, subheading, animations, tiles, offset, mobileOffset, leftToRight } = tileGroup;

    useEffect(() => {
        imagesLoaded(tilesEl.current, () => {
            new Masonry(tilesEl.current, {
                itemSelector: '.tiles__tile',
                percentPosition: true,
                transitionDuration: 0,
                originLeft: leftToRight,
            });
        });
    });

    return (
        <>
            { mobileOffset && (<div className="md:hidden" style={{ marginTop: mobileOffset }} />) }
            { heading && (
                first ? (
                    <h3
                        className={ `color-dark-blue text-center md:c6 md:o3 py-4 ${ subheading ? '' : 'pb-12 md:pb-24' }` }
                        dangerouslySetInnerHTML={{ __html: heading }}
                    />
                ) : (
                    <h4
                        className={ `font-bold color-dark-blue text-center md:c6 md:o3 py-4 ${ subheading ? '' : 'pb-12 md:pb-24' }` }
                        dangerouslySetInnerHTML={{ __html: heading }}
                    />
                )
            ) }
            { subheading && (
                <SubHeading subheading={ subheading } />
            ) }

            { offset && (<div className="hidden md:block" style={{ marginTop: offset }} />) }

            <div ref={ tilesEl } className={ `grid tiles ${ (tiles && tiles.length > 0) ? 'md:pb-24' : '' } mx-n6 md+:mx-auto` }>
                { tiles && tiles.map(tile => (
                    <Tile
                        key={ tile.filename }
                        tile={ tile }
                        currentDialog={ currentDialog }
                        setDialog={ setDialog }
                        unsetDialog={ unsetDialog }
                    />
                )) }
                { animations && animations.map((animation, key) => <Animation key={ key } animation={ animation } />) }
            </div>
        </>
    );
};

export default TileGroup;
