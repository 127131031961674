import { oneLine } from 'common-tags/es';

const data = {
    type: 'contentpage',
    path: '/measures/home',
    meta: {
        title: 'A place to call home | Child Poverty',
    },
    title: 'A healthy, warm, dry home',
    intro: [
        oneLine`<p>Damp mouldy homes cause respiratory illness, which can be aggravated by living in houses with poor 
                ventilation or inadequate heating.</p>`,
    ],
    image: {
        filename: 'DampHomes_2022.png',
        alt: 'Graph of children in homes that always have significant mould by ethnicity',
    },
    content: [
        oneLine`<p>Of all children, <b>6%</b> live in homes that always have major problems with damp or mould. 
                But <b>10%</b> of tamariki Māori and <b>12%</b> of Pacific children live in these conditions. <b>10%</b> of disabled
children live in these conditions.</p>`,
        oneLine`<p>Crowding, which is more common for Māori and Pacific whānau, increases the risk of transmitting diseases. While some families enjoy the benefits of multigenerational living together, they also need good 
                quality homes with enough room for everyone.</p>`,
        oneLine`<p>Aotearoa needs more houses, and developers should be supported and regulated to ensure new homes
                are accessible for everyone, including disabled people.</p>`,
        oneLine`<p>The vast majority of families in poverty and low income rent their homes. <b>11%</b> of children
                living in rented homes had a major problem with dampness or mould, compared with only
                <b>2%</b> of children in owner-occupied homes. If we are going to make a difference to housing
                quality for children in poverty, starting with rentals is key. Families who rent need the
                Government and landlords to have their back, with strong rules guaranteeing their homes
                are warm, dry and safe to live in.</p>`,
    ],
};

export default data;
