import React from 'react';
import { Link } from 'react-router-dom';

import Img from './img';

const Logo = ({ background }) => {
    const defaultLogo = 'PovertyMonitor_2022_REV.png';
    const logoColourMap = {
        'bg-dark-green': 'PovertyMonitor_2022_REV.png',
        'bg-white': 'PovertyMonitor_2022_POS.png',
        'bg-grey-20': 'PovertyMonitor_2022_POS.png',
    };
    const image = background in logoColourMap ? logoColourMap[background] : defaultLogo;

    return (
        <Link to="/">
            <Img
                filename={ image }
                className="w-48"
                alt="Child Poverty Monitor - Home"
            />
        </Link>
    );
};

export default Logo;
