import oneLine from 'common-tags/es/oneLine/oneLine';

import homepage from './homepage';
import income from './measures/income';
import essentials from './measures/essentials';
import food from './measures/food';
import home from './measures/home';
import health from './measures/health';
import about from './about';
import reducing_child_poverty from './measures/reducing-child-poverty';
import policy from './measures/policy';

const data = {
    pages: [
        homepage,
        income,
        essentials,
        food,
        home,
        health,
        about,
        reducing_child_poverty,
        policy,
    ],
    menu: [
        {
            name: 'Poverty measures',
            link: '/measures',
            submenu: [
                { name: 'Income', link: '/income' },
                { name: 'Essentials', link: '/essentials' },
                { name: 'Home', link: '/home' },
                { name: 'Health', link: '/health' },
                { name: 'Food', link: '/food' },
                { name: 'Reducing Child Poverty', link: '/reducing-child-poverty' },
                { name: 'Policy', link: '/policy' },
            ],
        }, {
            name: 'About the project',
            link: '/about',
        },
    ],
    general: {
        contact: {
            email: 'children@occ.org.nz',
        },
        sponsors: [
            {
                name: 'Office of the Children\'s Commissioner',
                url: 'https://www.occ.org.nz/',
                image: {
                    filename: 'childrens-commissioner-logo-colour.png',
                },
            }, {
                name: 'JR McKenzie Trust',
                url: 'https://www.jrmckenzie.org.nz/',
                image: {
                    filename: 'jr-mckenzie-trust-logo-colour.png',
                    classes: 'w-20',
                },
            }, {
                name: 'Otago University',
                url: 'https://www.otago.ac.nz/',
                image: {
                    filename: 'otago-university-logo-colour.png',
                    classes: 'w-64',
                },
            },
        ],
        message: oneLine`The 2022 Child Poverty Monitor includes several sources of data including the 2018 Census,
                         other surveys including the Household Economic Survey for year ending June 2021,
                         administrative data to June 2022, and health data. The sources of all data on this site are
                         available in the <a class="font-semibold underline-link-basic" href="https://www.nzchildren.co.nz/" target="_blank">Child Poverty Monitor: Technical Report</a>, 
                         produced by the New Zealand Child and Youth Epidemiology Service at the University of Otago. 
                         To find out more about child poverty, you can read the Children’s Commissioner’s Expert Advisory Group report
                         <a class="font-semibold underline-link-basic" href="https://www.occ.org.nz/publications/reports/final-report-solutions-to-child-poverty-evidence-for-action/" target="_blank"> 
                         Solutions to Child Poverty in New Zealand: Evidence for Action.</a>`,
        technicalReport: {
            download: 'http://hdl.handle.net/10523/13707',
            online: 'https://nzchildren.co.nz/',
        },

    },
};

export default data;
