import React from 'react';

const Img = (props) => {
    const { filename, alt, className, style } = props;

    return (
        <img
            src={ `/static/images/${ filename }` }
            alt={ alt }
            className={ className }
            style={ style }
        />
    );
};

export default Img;
