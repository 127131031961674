import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';

import scrollToTop from '../../utils/scrollToTop';
import Menu from '../menu';
import Page from './page';
import Footer from '../footer';
import { trackPage } from "../../utils/analytics";

const ContactPage = inject(stores => ({ general: stores.store.general }))(observer(({ general, page }) => {
    useEffect(() => {
        scrollToTop();
        trackPage(page);
    }, []);
    const { contact: { email, twitter, facebook } } = general;

    return (
        <>
            <Menu />
            <Page page={ page }>
                <div className='bg-white' role="main">
                    <div className='container pb-32'>
                        <div className='md:o1 md:c10'>
                            <p><strong>Email us</strong></p>
                            <h4 className='my-4 md:mb-12'>
                                <a href={ `mailto:${ email }` } className='underline-link'>
                                    { email }
                                </a>
                            </h4>
                            <p><strong>Twitter</strong></p>
                            <h4 className='my-4 md:mb-12'>
                                <a href={ `${ twitter }` } className='underline-link'>
                                    @povertymonitor
                                </a>
                            </h4>
                            <p><strong>Facebook</strong></p>
                            <h4 className='my-4'>
                                <a href={ `${ facebook }` } className='underline-link'>
                                    Child Poverty Monitor
                                </a>
                            </h4>
                        </div>
                    </div>
                </div>
            </Page>
            <Footer />
        </>
    );
}));

export default ContactPage;
