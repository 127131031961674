import { oneLine } from 'common-tags/es';

const data = {
    type: 'contentpage',
    path: '/measures/income',
    meta: {
        title: 'Adequate income | Child Poverty',
    },
    title: 'Family Income',
    intro: [
        oneLine`<p>Recent increases in benefits and minimum wages have successfully raised many families above the 
                Government’s income poverty line*. In the years since 2017/18, child poverty, on one measure, has gone down <b>from 22.8% to 16.3%</b>. 
                The Government’s three year ‘interim’ target for 2021/22 was <b>18.8%</b> so this has been achieved early.</p>`,
    ],
    image: {
        filename: 'Income_2022.png',
        alt: 'Graph of Households with children living below the poverty line by ethnicity',
    },
    content: [
        oneLine`<p>However, the overall picture hides inequities. The income poverty rate (using a baseline year
                   for comparisons, and after housing costs are deducted) was highest for tamariki Māori at
                   <b>17.8%</b>. For disabled children, it is similar, at <b>17.6%</b>, while for Pacific children it has come
                   down to <b>16.3%</b> on par with all children in this year. In comparison, European (Pakeha)
                   children had poverty rate of <b>14.1%</b> on this measure.</p>`,
        oneLine`<p>The Government’s other main income target is to reduce the percentage of children in
                   households earning less than 50% of the contemporary (same year) median equivalised
                   income (before housing costs are deducted) to <b>5%</b> by 2028. In 2020/21 <b>13.6%</b> of the total
                   population of children were living in this level of low income. On this measure, there is a long
                   way to go before this target is met for some groups. <b>18.1%</b> of Māori and <b>17.2%</b> of Pacific
                   children are experiencing this level of low income, as are <b>15.5%</b> of disabled children. The
                   three-year interim target (<b>10.5%</b>) is close to being met only for European children, of whom
                   <b>9.7%</b> are living in this level of income poverty.</p>`,
        oneLine`<p class="text-base">*This measure in the graph is the proportion of children living in households with an
                   equivalised disposable household income less than 50% of median income in 2017/18, after
                   housing costs.</p>`,
    ],
};

export default data;
