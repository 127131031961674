import React from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import Logo from './logo';
import Img from './img';
import Svg from './svg';

const Footer = inject(stores => ({ general: stores.store.general }))(observer(({ general, background = 'bg-grey-20' }) => {
    const { contact: { email, twitter, facebook }, sponsors, message } = general;
    return (
        <footer className={ background }>
            <div className="container pt-10 pb-10 md:pb-64">
                <div className="grid">
                    <div className="md:c2 mb-8">
                        <Logo background={ background } />
                    </div>
                    <div className="md:c4">
                        <p>
                            <strong>Email us</strong>
                        </p>
                        <p className="mb-4 md:mb-8">
                            <a className="secondary-link" href={ `mailto:${ email }` }>{ email }</a>
                            <br className="hidden sm:inline" /> Read about community stories here: <a className="font-semibold underline" href="https://www.occ.org.nz/wellbeing/" target="_blank" rel="noreferrer">occ.org.nz/wellbeing</a>
                        </p>
                        { (facebook || twitter) && (
                            <p className="mb-8">
                                { facebook && (
                                    <a href={ facebook } className="mr-4" aria-label="Child Poverty Monitor on Facebook">
                                        <Svg name="facebook" width="36px" height="36px" />
                                    </a>
                                ) }
                                { twitter && (
                                    <a href={ twitter } aria-label="Child Poverty Monitor on Twitter">
                                        <Svg name="twitter" width="36px" height="36px" />
                                    </a>
                                ) }
                            </p>
                        ) }
                    </div>
                    <div className="md:c6">
                        { sponsors && (
                            <div className="md:flex md:flex-jc-between">
                                { sponsors.map(sponsor => {
                                    const { name, url, image: { filename, classes } } = sponsor;
                                    return (
                                        <div key={ url } className="flex flex-ai-center mb-4">
                                            <a href={ url } title={ name } target="_blank" rel="noreferrer">
                                                <Img filename={ filename } alt={ name } className={ classes || 'w-32' } />
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        ) }
                        <p dangerouslySetInnerHTML={{ __html: message }} />
                    </div>
                </div>
            </div>
        </footer>
    );
}));

export default Footer;
