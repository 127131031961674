import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useTransition, animated } from 'react-spring/web.cjs';
import lottie from 'lottie-web';
import { observer, inject } from 'mobx-react';

import Logo from './logo';
import lottieIeFixer from '../utils/lottie.ie.fixer';


const MenuItem = ({ name, link, className = '' }) => {
    return (
        <NavLink className={ `global-nav__item ${ className }` } to={ link } exact>
            { name }
        </NavLink>
    );
};

const SubMenu = ({ rootName, rootLink, submenu }) => {
    const [isOpen, setIsOpen] = useState(false);
    const openMenu = () => setIsOpen(true);
    const closeMenu = () => setIsOpen(false);
    return (
        <div className='inline-block relative' onMouseEnter={ openMenu } onMouseLeave={ closeMenu }>
            <span className='global-nav__item py-1 pl-16'>
                { rootName }
            </span>
            <div className={ `global-nav__dropdown bg-white ${ isOpen ? 'global-nav__dropdown-open' : '' }` }>
                { submenu.map(item => {
                    const { name, link } = item;
                    const fullLink = rootLink + link;
                    return (
                        <div key={ link }>
                            <MenuItem name={ name } link={ fullLink } className='py-1' />
                        </div>
                    );
                }) }
            </div>
        </div>
    );
};

const MobileMenuIcon = React.memo(({ isOpen, setIsOpen }) => {
    const animationEl = useRef(null);
    const animationWidthContainerEl = useRef(null); // Needed for IE11 which needs to know the container with the actual width set on it.
    const path = '/static/images/4649-menu.json';
    const name = 'menu';
    const speed = 5;
    const onClick = () => {
        if (isOpen) {
            setIsOpen(false);
            lottie.setDirection(-1, name);
            lottie.play(name);
        } else {
            setIsOpen(true);
            lottie.setDirection(1, name);
            lottie.play(name);
        }
    };

    useEffect(() => {
        const animation = lottie.loadAnimation({
            path,
            name,
            loop: false,
            autoplay: false,
            container: animationEl.current,
            renderer: 'svg',
        });
        lottie.setSpeed(speed, name);
        animation.addEventListener('DOMLoaded', () => {
            lottieIeFixer(animationWidthContainerEl.current, true);
        });
    }, []);

    return (
        <div className="md:hidden ml-auto w-10 pt-10" ref={ animationWidthContainerEl }>
            <button type="button" onClick={ onClick }>
                <div ref={ animationEl } />
            </button>
        </div>
    );
});

const MobileMenu = ({ menu, isOpen, background }) => {
    const transitions = useTransition(isOpen, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    });
    return (
        transitions.map(({ item, key, props }) => (
            item && (
                <animated.div key={ key } style={ props } className={ `absolute md:hidden z-90 w-100 py-3 ${ background }` }>
                    <div className="container">
                        { menu.map(item => {
                            const { name, submenu } = item;
                            const rootLink = item.link;
                            return (
                                <div key={ rootLink }>
                                    { submenu ? (
                                        <span className="global-nav__item py-1">
                                            { name }
                                        </span>
                                    ) : (
                                        <MenuItem name={ name } link={ rootLink } className="py-1" />
                                    )}
                                    { submenu && (
                                        <div className="pl-2">
                                            {submenu.map(subitem => {
                                                const { name, link } = subitem;
                                                const fullLink = rootLink + link;
                                                return (
                                                    <div key={ link }>
                                                        <MenuItem name={ name } link={ fullLink } className="py-1" />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) }
                                </div>
                            );
                        }) }
                    </div>
                </animated.div>
            )
        ))
    );
};

const Menu = inject(stores => ({ menu: stores.store.menu }))(observer(({ menu, background = 'bg-white' }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <header className={ `global-nav ${ background }` }>
            <div className="container">
                <div className="flex flex-jc-start py-6 md:pb-12">
                    <div>
                        <Logo background={ background } />
                    </div>

                    <MobileMenuIcon isOpen={ isOpen } setIsOpen={ setIsOpen } />
                    <div className="hidden md:block md:pt-12 md:ml-auto ">
                        { menu.map(item => {
                            const { name, link, submenu } = item;
                            return (
                                submenu ? (
                                    <SubMenu
                                        key={ link }
                                        rootName={ name }
                                        rootLink={ link }
                                        submenu={ submenu }
                                        background={ background }
                                    />
                                ) : (
                                    <MenuItem key={ link } name={ name } link={ link } className="py-1 pl-16" />
                                )
                            );
                        }) }
                    </div>
                </div>
            </div>
            <MobileMenu menu={ menu } isOpen={ isOpen } background={ background } />
        </header>
    );
}));

export default Menu;
