import React, { useEffect } from 'react';
import { isString } from 'lodash/lang';
import { inject, observer } from 'mobx-react';

import Page from './page';
import Menu from '../menu';
import Footer from '../footer';
import Img from '../img';
import scrollToTop from '../../utils/scrollToTop';
import { trackPage } from '../../utils/analytics';

const Intro = ({ intro }) => {
    return (
        isString(intro) ? (
            <h4 className='mb-12' dangerouslySetInnerHTML={{ __html: intro }} />
        ) : (
            <>
                { intro.map((i, key) => (
                    key === 0 ? (
                        <h4 key={ key } className='mb-12' dangerouslySetInnerHTML={{ __html: i }} />
                    ) : (
                        <h5 key={ key } className='mb-12' dangerouslySetInnerHTML={{ __html: i }} />
                    )
                )) }
            </>
        )
    );
};

const ReportLinks = ({ technicalReport }) => {
    const { download, online } = technicalReport;
    return (
        <>
            <p>To view an online version of the technical report, <a rel="noreferrer noopener" target="_blank" href={ online } className="font-semibold underline-link color-orange">click here.</a></p>
            <p>To download a copy, <a rel="noreferrer noopener" target="_blank" href={ download } className="font-semibold underline-link color-orange">click here</a></p>
        </>
    );
};

const Stacked = ({ page, technicalReport }) => {
    const { title, intro, image, content } = page;
    return (
        <>
            <h2 className='mb-4'>{ title }</h2>
            <Intro intro={ intro } />
            { image && (
                <div className='text-center'>
                    <Img filename={ image.filename } alt={ image.alt } />
                </div>
            ) }
            { isString(content) ? (
                <div dangerouslySetInnerHTML={{ __html: content }} />
            ) : (
                <div className='grid grid--gutters'>
                    <div className='md:c6'>
                        { content.map((c, key) => (
                            <div key={ key } className='mb-4' dangerouslySetInnerHTML={{ __html: c }} />
                        )) }
                        <ReportLinks technicalReport={ technicalReport } />
                    </div>
                </div>
            ) }
        </>
    );
};

const ImageRight = ({ page, technicalReport }) => {
    const { title, intro, image, content } = page;
    return (
        <div className='grid grid--gutters'>
            <div className='md:c6'>
                <h2 className='mb-4'>{ title }</h2>
                <Intro intro={ intro } />
                { isString(content) ? (
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                ) : (
                    content.map((c, key) => (
                        <div key={ key } className='mb-4' dangerouslySetInnerHTML={{ __html: c }} />
                    ))
                ) }
                <div className='mb-4'>
                    <ReportLinks technicalReport={ technicalReport } />
                </div>
            </div>
            { image && (
                <div className='md:c6'>
                    <div className='text-center'>
                        <Img filename={ image.filename } alt={ image.alt } />
                    </div>
                </div>
            ) }
        </div>
    );
};

const ContentPage = inject(stores => ({ general: stores.store.general }))(observer(({ general, page }) => {
    const { layout } = page;
    const { technicalReport } = general;

    useEffect(() => {
        scrollToTop();
        trackPage(page);
    }, []);

    const layoutMap = {
        'stacked': Stacked,
        'image-right': ImageRight,
    };
    const Layout = layoutMap[layout];

    return (
        <>
            <Menu />
            <Page page={ page }>
                <div className="bg-white" role="main">
                    <div className="container pb-32">
                        <div className="md:o1 md:c10">
                            <Layout technicalReport={ technicalReport } page={ page } />
                        </div>
                    </div>
                </div>
            </Page>
            <Footer />
        </>
    );
}));

export default ContentPage;
