import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import { Provider, observer } from 'mobx-react';

import HomePage from './pages/homepage';
import ContentPage from './pages/contentpage';
import ContactPage from './pages/contactpage';
import NotFoundPage from './pages/notfoundpage';
import notFoundData from '../data/notfound';

const App = observer(({ data, store }) => {
    window.DATA = data;
    const { pages, menu } = store;
    const pageMap = {
        'homepage': HomePage,
        'contentpage': ContentPage,
        'contactpage': ContactPage,
    };
    return (
        <Provider store={ store }>
            <Router>
                <Switch>
                    { pages.map(page => {
                        const { path, type } = page;
                        const Page = pageMap[type];
                        return (
                            <Route key={ path } path={ path } exact>
                                <Page page={ page } menu={ menu } />
                            </Route>
                        );
                    }) }
                    <Route>
                        <NotFoundPage page={ notFoundData } />
                    </Route>
                </Switch>
            </Router>
        </Provider>
    );
});

export default App;
