import { oneLine } from 'common-tags/es';

const data = {
    type: 'homepage',
    path: '/',
    title: 'The dream',
    content: [
        oneLine`Imagine if all mokopuna in <br class="hidden md+:inline" />
                Aotearoa grew up in a whānau <br class="hidden md+:inline" />
                that has what they need to thrive… `,
        oneLine`If all children had a warm dry home,<br class="hidden md+:inline" />
                could feel a part of a community,<br class="hidden md+:inline" />
                and their whānau had enough money for the basics`,
        oneLine`What if we knew which children were more likely to miss out…. `,
        oneLine`<p>What if we knew which children were missing out?<br class="hidden md+:inline" />
                Would we change course?</p>`,
    ],
    meta: {
        title: 'Tracking progress on reducing child poverty in New Zealand | Child Poverty',
    },
    tilesGroups: [
        {
            name: 'first-tile-group',
            first: true,
            heading: oneLine`Poverty in childhood has ongoing impacts on people’s health, education and wellbeing.`,
            subheading: [
                oneLine`<p>Child poverty has reduced recently. However, certain cohorts <br class="hidden sm:inline" />
                            of children and their families are more affected by poverty. <br class="hidden sm:inline" />
                            Māori, Pacific and disabled children are much more likely to be <br class="hidden sm:inline" />
                            doing it tough than non-Māori, non-Pacific and non-disabled. <br class="hidden sm:inline" />
                            Also, sole parent families and households dependent on benefits <br class="hidden sm:inline" />
                            are most likely to be in the most severe forms of poverty.</p>`,
                oneLine`<p>To meet the Government’s <a class="font-semibold underline" href="/measures/reducing-child-poverty">poverty targets</a> we need a concerted <br class="hidden sm:inline" />
                           and continued effort to achieve better outcomes for these groups.</p>`,
                oneLine`<p>We won’t properly meet our poverty reduction targets unless we <br class="hidden sm:inline" />reduce child poverty for all.</p>`,
                oneLine`<p class="md:mb-16">Scroll to find out more.</p>`,
            ],
            leftToRight: true,
            animations: [],

            tiles: [
                {
                    filename: '1_Mountain.png',
                    mobileFilename: '2021_mobile1.png',
                    offset: '27.3%',
                    animations: [
                        {
                            filename: '01-FAMILY-INCOME-BIRD.json',
                            type: 'lottie',
                            loop: true,
                            width: '43%',
                            top: '1.2%',
                            left: '1.7%',
                            mobileTop: '8%',
                            mobileLeft: '9.5%',
                            mobileWidth: '38%',
                        },
                    ],
                    dialogs: [
                        {
                            title: 'Family Income',
                            number: 1,
                            showTitle: true,
                            first: true,
                            position: 'bottom',
                            top: '-13%',
                            left: '4%',
                            mobileTop: '0', // TODO
                            mobileLeft: '55%', // TODO
                            mobileWidth: '90%', // TESTING
                            // tabletWidth: '400px', // TESTING
                            width: '532px',
                            height: '310px',
                            content: [
                                oneLine`<b>16.3%</b> of mokopuna live in low-income households. <br class="hidden sm:inline" />The target on this measure by June 2028 is <b>10%</b>. <br class="hidden sm:inline" />
                                        <i class="text-xs">(Note this is the 50% median, after housing cost, fixed line measure).</i>
                                        `,
                                oneLine`For Māori, <b>17.8%</b> are in income-poor households, for Pacific, it’s <b>16.3%</b>, for Pākehā it's <b>14.1%</b> and for disabled it is <b>17.6%</b>.`,
                            ],
                            link: '/measures/income',
                            linkText: 'Read more*',
                        },
                    ],
                },
                {
                    filename: '2_Graduation.png',
                    mobileFilename: '2021_mobile2.png',
                    offset: '75.2%',
                    mobileOffset: '0',
                    animations: [
                        {
                            filename: '02-ESSENTIALS-PHOTOGRAPHER.json',
                            type: 'lottie',
                            loop: true,
                            width: '7.6%',
                            top: '54.8%',
                            left: '33%',
                            mobileTop: '53%',
                            mobileLeft: '34%',
                        }, {
                            filename: '02-ESSENTIALS-TREE.json',
                            type: 'lottie',
                            loop: true,
                            width: '17%',
                            top: '40%',
                            left: '79%',
                            mobileTop: '41%',
                            mobileLeft: '79%',
                        },
                    ],
                    dialogs: [
                        {
                            title: 'Access to Essentials',
                            number: 1,
                            showTitle: true,
                            first: true,
                            position: 'bottom',
                            top: '24%',
                            left: '11%',
                            // mobileTop: '16%', // TODO
                            // mobileLeft: '55%', // TODO
                            mobileWidth: '90%',
                            // tabletWidth: '400px', // TESTING
                            width: '532px',
                            height: '425px',
                            content: [
                                oneLine`<b>11.0%</b> of mokopuna live in households experiencing material hardship. 
                                        The target by June 2028 is <b>6%</b>.`,
                                oneLine`The rates of material hardship for mokopuna Māori and disabled children are twice as high
                                        as Pākehā and non-disabled, and for Pacific children, it’s three times as high. About one in
                                        four Pacific children are in households in material hardship.`,
                            ],
                            link: '/measures/essentials',
                        },
                    ],
                },
                {
                    filename: '3_Wharf.png',
                    mobileFilename: '2021_mobile3.png',
                    offset: '26.9%',
                    mobileOffset: '0',
                    animations: [
                        {
                            filename: '03-HOME-BEACHBALL.json',
                            type: 'lottie',
                            loop: true,
                            width: '26%',
                            top: '-4.5%',
                            left: '6%',
                            mobileTop: '0%',
                            mobileLeft: '5%',
                        }, {
                            filename: '03-HOME-FISHERMAN.json',
                            type: 'lottie',
                            loop: true,
                            width: '25%',
                            top: '48.6%',
                            left: '24%',
                            mobileTop: '50%',
                            mobileLeft: '24%',
                        }, {
                            filename: '03-HOME-ROOF.json',
                            type: 'lottie',
                            loop: true,
                            width: '44%',
                            top: '-12%',
                            left: '61%',
                            mobileTop: '-9%',
                            mobileLeft: '60%',
                        },
                    ],
                    dialogs: [
                        {
                            title: 'Warm Healthy Homes',
                            number: 1,
                            showTitle: true,
                            first: true,
                            position: 'bottom',
                            top: '-6%',
                            left: '4%',
                            // mobileTop: '16%', // TODO
                            // mobileLeft: '55%', // TODO
                            width: '532px',
                            mobileWidth: '90%',
                            height: '300px',
                            content: [
                                oneLine`In 2020/21, <b>6%</b> of children lived in homes with major dampness</br> or mould problems, down from <b>9%</b> in 2017/18.`,
                                oneLine`But for Pacific mokopuna it’s still twice that at <b>12%</b>, and for tamariki Māori it is also higher at <b>10%</b>.`,
                            ],
                            link: '/measures/home',
                        },
                    ],
                },
                {
                    filename: '4_Marae.png',
                    mobileFilename: '2021_mobile4.png',
                    offset: '36.1%',
                    mobileOffset: '0',
                    animations: [
                        {
                            filename: '04-FOOD-APPLE.json',
                            type: 'lottie',
                            loop: true,
                            width: '20%',
                            top: '60%',
                            left: '59%',
                            mobileTop: '61%',
                            mobileLeft: '62%',
                            // noAnimateIE: true, // WAT??
                        },
                    ],
                    dialogs: [
                        {
                            title: 'Access to Healthy Food ',
                            number: 3,
                            showTitle: true,
                            position: 'bottom',
                            top: '-7%',
                            left: '11%',
                            // mobileTop: '5%', // TODO
                            // mobileLeft: '38%', // TODO
                            width: '532px',
                            mobileWidth: '90%',
                            height: '335px',
                            content: [
                                oneLine`In 2020/21 one in six children lived in households where food ran out sometimes (<b>12.0%</b>) or often (<b>2.9%</b>) due to a lack of money.`,
                                oneLine`Māori (<b>26.4%</b>) and Pacific (<b>37.3%</b>) are most likely to sometimes, or often, run out of food, compared with European children (<b>10.9%</b>).`,
                            ],
                            link: '/measures/food',
                        },
                    ],
                },
                {
                    filename: '5_Playground.png',
                    mobileFilename: '2021_mobile5.png',
                    offset: '43.4%',
                    mobileImageWidth: '90%',
                    animations: [
                        {
                            filename: '05-HEALTH-PARAGLIDER.json',
                            type: 'lottie',
                            loop: true,
                            width: '29%',
                            top: '5%',
                            left: '2%',
                            mobileTop: '-5%',
                            mobileLeft: '12%',
                            mobileWidth: '38%',
                        },
                        {
                            filename: '05-HEALTH-WATERFALL.json',
                            type: 'lottie',
                            loop: true,
                            width: '40.5%',
                            top: '11%',
                            left: '52%',
                            mobileTop: '16%',
                            mobileLeft: '53%',
                            mobileWidth: '38.5%',
                        },
                    ],
                    dialogs: [
                        {
                            title: 'Good health',
                            number: 6,
                            showTitle: true,
                            top: '0%',
                            left: '4%',
                            // mobileTop: '67%',
                            // mobileLeft: '38%',
                            width: '532px',
                            mobileWidth: '90%',
                            height: '360px',
                            content: [
                                oneLine`The Covid-19 pandemic coincided with a drop in immunisation rates for diseases other than Covid-19.`,
                                oneLine`In 2020/21 only <b>85.7%</b> of all babies were fully immunised at age 8 months, for Pacific it was <b>84.2%</b> and for Māori, it is even lower at <b>72.1%</b>. The target for a protected community is <b>95%</b>.`,
                            ],
                            link: '/measures/health',
                        },
                    ],
                },
                {
                    filename: '6_Kea.png',
                    mobileFilename: '2021_mobile6.png',
                    animations: [],
                    dialogs: [],
                },
            ],
        },
        {
            name: 'second-tile-group',
            heading: '<span>Every child living <br class="md:hidden inline" /> their best life</span>',
            subheading: [
                oneLine`<p>While poverty can have serious impacts for children and their families, many communities
                            are doing great work to mitigate the impacts of poverty. People helping people is a theme this year in the Child Poverty Monitor technical report.</p>`,
                oneLine`<p>We tell the stories of some community organisations and young people who are making lives better for those with low
                            incomes and living in hardship.</p>`,
                oneLine`<p>In doing this work these young people are also building their mana, their sense of self-worth 
                            and their ability to overcome challenges.</p>`,
                oneLine`<p>Go to <a class="font-semibold underline-link" href="https://www.occ.org.nz/wellbeing/">www.occ.org.nz/wellbeing</a> to read these stories, told through the voices of rangatahi.</p>`,
            ],
            // offset: '10%',
            mobileOffset: '4rem',
            leftToRight: true,
            animations: [],
            tiles: [],
        },
        {
            name: 'third-tile-group',
            heading: 'Policy Decisions',
            mobileOffset: '4rem',
            subheading: [
                oneLine`<p>It's time to recognise and remove the barriers getting in the way of all tamariki 
                        living their best lives. That means confronting the impact that racism, ableism and 
                        colonisation is having on children and their families. Income matters too. 
                        Policy can make a difference.</p>
                        <a class="font-semibold underline-link" href="/measures/policy">Read more</a>.`,
            ],
        },
        {
            name: 'fourth-tile-group',
            heading: 'About the Child Poverty Monitor',
            mobileOffset: '4rem',
            subheading: oneLine`The annual Child Poverty Monitor reports on child <br class="hidden sm:inline" />
                poverty in Aotearoa New Zealand and its impacts. <br class="hidden sm:inline" />
                <a class="font-semibold underline-link" href="/about">Click to find out more</a>.`,
        },
    ],
};

export default data;
